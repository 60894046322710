import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Testing Gatsby', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: 'Welcome to this test Website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Samrat',
  subtitle: 'Exploring Gatsby',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'AmazonPhoto.jpg',
  paragraphOne: 'Lets see if these edits show up on the page',
  paragraphTwo: 'I would love to have a real website with some cool functionality show up here',
  paragraphThree: 'CloudFront is the best!',
  resume: 'https://www.linkedin.com/in/samratkarak', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Check out this cool blogpost about CloudFront real-time logs',
    info: 'Let me know if you have questions',
    info2: '',
    url: 'https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/real-time-logs.html',
    repo: 'https://github.com/samratkarak/gatsby-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: '',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: '',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: '',
    },
    {
      id: nanoid(),
      name: 'github',
      url: '',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
